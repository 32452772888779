const SELECTOR = '#qualifier_requirement_phase_id';

$(document).on('change', SELECTOR, (e) => {
  const value = $(e.target).val();

  if (value) {
    const companyId = $('#new-qualifier-requirement-modal-form').data().company.id;

    $.ajax({
      type: 'GET',
      url: `/trabajar/${companyId}/recruitment_process/phases/${value}/statuses`,
      dataType: 'json',
      success(result) {
        const emptyOption = "<option value=''></option>";
        const options = result.reduce(
          (acc, data) => `${acc}<option value='${data[1]}'>${data[0]}</option>`,
          emptyOption,
        );

        $('.modal.active').find('#qualifier_requirement_status_id').html(options);
        $('.modal.active').find('#qualifier_requirement_status_id').chosen('destroy');
        $('.modal.active').find('#qualifier_requirement_status_id').chosen();
      },
    });
  }
});
